import { useChannel } from 'ably/react';
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { setUrlCheckData } from '../redux-toolkit/reducer/ablyReducer';

export default function useAblyChannelHook() {
    const [message, setAblyMessage] = useState({});

    const dispatch = useDispatch();

    const { currentPortal, command } = useSelector(
        ({ portal, user, ably }) => ({
            currentPortal: portal?.portal,
            isSignOutUser: user.isSignOutUser,
            command: ably?.message,
        }),
        isEqual,
    );

    const { channel } =
        useChannel('portals', (message) => {
            if (message?.name === currentPortal?.portal_id) {
                setAblyMessage(message);
            }
        }) || {};

    useEffect(() => {
        return () => {
            if (channel) {
                // eslint-disable-next-line no-console
                console.log('unsubscribing from channel');
                channel.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            try {
                const command = message?.data?.message || '';
                // @ts-expect-error
                const commandData = message?.data?.data || {};
                if (command === 'check-self-diagnose-result') {
                    const transformedData = Object.entries(commandData).map(([key, value]) => ({
                        host: key,
                        // @ts-expect-error
                        alive: value?.alive,
                        reason: value?.reason,
                    }));
                    dispatch(setUrlCheckData(transformedData));
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }
    }, [message, dispatch]);

    const publishMessage = useCallback(
        (messageData) => {
            try {
                if (channel) {
                    channel.publish(currentPortal?.portal_id, messageData);
                } else {
                    // eslint-disable-next-line no-console
                    console.warn('Channel not ready for publishing. Ensure subscription is complete.');
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        [channel, currentPortal?.portal_id],
    );

    useEffect(() => {
        if (command) {
            publishMessage({
                message: command,
            });
        }
    }, [command, publishMessage]);

    return { message, channel };
}
