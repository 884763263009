import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cameraConfigPostAPI, projectorConfigPostAPI } from '../../api/settingsConfigPostAPI';

import { CameraConfigModel, ProjectorConfigModel } from '../model/settingsConfigModel';

const initialState = {
    loading: false,
    error: false,
    errorObj: {} as any,
    cameraConfig: {} as CameraConfigModel,
    projectorConfig: {} as ProjectorConfigModel,
    isScreenShotTaken: false,
};

export const updateCameraConfigByPortal = createAsyncThunk(
    'cameraConfig/updateCameraConfigByPortal',
    async (cameraConfig: any) => {
        return await cameraConfigPostAPI(cameraConfig);
    },
);

export const updateProjectorConfigByPortal = createAsyncThunk(
    'cameraConfig/updateProjectorConfigByPortal',
    async (projectorConfig: any) => {
        return await projectorConfigPostAPI(projectorConfig);
    },
);

const cameraConfigSlice = createSlice({
    name: 'cameraConfig',
    initialState,
    reducers: {
        setCameraConfigNull: (state, action) => {
            state.cameraConfig = action.payload;
        },
        setScreenShotTaken: (state, action) => {
            state.isScreenShotTaken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateCameraConfigByPortal.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCameraConfigByPortal.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.cameraConfig = action.payload.data;
            })
            .addCase(updateCameraConfigByPortal.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(updateProjectorConfigByPortal.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProjectorConfigByPortal.fulfilled, (state, action) => {
                state.error = false;
                state.loading = false;
                state.cameraConfig = action.payload.data;
            })
            .addCase(updateProjectorConfigByPortal.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
                state.errorObj = action.error;
            });
    },
});
export const { setCameraConfigNull, setScreenShotTaken } = cameraConfigSlice.actions;
export default cameraConfigSlice.reducer;
