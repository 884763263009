import useAblyChannelHook from 'hooks/useAblyChanel';
import { createContext, useContext, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setScreenShotTaken } from 'redux-toolkit/reducer/cameraConfigReducer';
import useAblyPortalPresenceHook from 'hooks/useAblyPortalPresenceHook';
import useAblyPresenceHook from 'hooks/useAblyPresenceHook';
import useVersionUpdateHook from 'hooks/useVersionUpdateHook';
import { setLogsUploaded } from 'redux-toolkit/reducer/portalReducer';

const AblyStateContext = createContext(null);

function AblyStateProvider({ children }) {
    const dispatch = useDispatch();
    const { portal_id } = useSelector((state) => state?.portal?.portal);
    const { message, channel } = useAblyChannelHook();

    useAblyPresenceHook();
    useAblyPortalPresenceHook({ channel });
    useVersionUpdateHook({ message });
    const isScreenShotTaken = useMemo(() => {
        return message?.data?.message === 'SCREENSHOT_TAKEN';
    }, [message]);

    const isLogsUploaded = useMemo(() => {
        return message?.data?.message === 'log-uploaded-successfully' && message?.name === portal_id;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    useEffect(() => {
        dispatch(setScreenShotTaken(isScreenShotTaken));
    }, [dispatch, isScreenShotTaken]);

    useEffect(() => {
        dispatch(setLogsUploaded(isLogsUploaded));
    }, [dispatch, isLogsUploaded]);

    return <AblyStateContext.Provider value={{}}>{children}</AblyStateContext.Provider>;
}

function useAblyStateContext() {
    const context = useContext(AblyStateContext);
    if (!context) {
        throw new Error('useAblyStateContext must be used within the AblyStateProvider');
    }
    return context;
}

export { AblyStateProvider, useAblyStateContext };
