import { apiPost } from './axios';
import { ENDPOINT_DEV } from './configs';
import { IOConfigModel } from '../redux-toolkit/model/IOConfigModel';

interface IOConfig {
    orgId: string;
    portalId: string;
    io: IOConfigModel;
    isAutoUpdate?: boolean;
    activityLog?: string[];
}

export const ioConfigPostAPI = async ({ orgId, portalId, io, isAutoUpdate, activityLog }: IOConfig) => {
    return await apiPost(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/iodevices`, {
        ...io,
        isAutoUpdate: isAutoUpdate,
        activityLog: activityLog,
    });
};
