import {
    CameraConfigModel,
    ProjectorConfigModel,
    iodevicesConfigModel,
} from '../redux-toolkit/model/settingsConfigModel';
import { DISPLAY_TYPE_LCD_LED } from './Utils';

const getDefaultConfigSettings: {
    cameraConfig: CameraConfigModel;
    orientation: ProjectorConfigModel;
    iodevices?: iodevicesConfigModel;
} = {
    cameraConfig: {
        straighten: 0,
        zoom: 1,
        moveX: 0,
        moveY: 0,
        rotation: 0,
        projectorRotation: 0,
        screenOrientationMode: 'landscape',
        video: {
            encodings: {
                low: {
                    maxFramerate: 15,
                    maxBitrate: {
                        name: '100kbps',
                        value: 100000,
                    },
                    scaleResolutionDownBy: 6,
                },
                high: {
                    maxFramerate: 30,
                    maxBitrate: {
                        name: '5mbps',
                        value: 5000000,
                    },
                    scaleResolutionDownBy: 1,
                },
                medium: {
                    maxFramerate: 20,
                    maxBitrate: {
                        name: '500kbps',
                        value: 500000,
                    },
                    scaleResolutionDownBy: 1.5,
                },
            },
        },
    },
    orientation: {
        blackspot: {
            moveX: 0,
            moveY: 0,
            radius: 0,
            enabled: false,
            showGrid: false,
        },
        camera: 0,
        config: {
            rows: 2,
            displayOrientation: 0,
            columns: 2,
            individualAspectRatio: '16:9',
            individualScreenSize: 55,
            headerOptionsX: 15,
            headerOptionsY: 18,
            headerOptionsD: 25,
            minimal3DFrameThickness: 5,
            maximal3DFrameThickness: 15,
            showVirtual3DframeIfARmismatch: 'Y',
            gradientColor1: '#5F6985',
            gradientColor2: '#878EA0',
            outerFrameThickness: 0.0,
            gradientDirectionTop: 45,
            gradientDirectionLeft: 0,
            gradientDirectionRight: 45,
            gradientDirectionBottom: 0,
            maxStrech: 0.0,
            fontSizeInPercentage: 100,
            frameShift: 0,
            screenBrightness: 60,
        },
        displayType: DISPLAY_TYPE_LCD_LED,
        projector: 0,
        projectorARandDF: {
            lowerEdge: 100,
            projectorAR: '4:3',
            leftEdge: 100,
            upperEdge: 100,
            rightEdge: 100,
            projectorOrientation: '0',
            headerOptionsD: 25,
            headerOptionsX: 15,
            headerOptionsY: 18,
            maxStrech: 0,
        },
        projectorRotation: 0,
        qrNotiBanner: 25,
        screenOrientation: 0,
    },
    iodevices: {
        audioInput: {
            originalDeviceLabel: '',
            deviceLabel: '',
            deviceLabelWithoutIds: '',
            deviceId: '',
            toHide: false,
        },
        echoCancellation: false,
        screenDetails: {},
        autoGainControl: false,
        noiseSuppression: false,
        videoInput: [
            {
                originalDeviceLabel: '',
                deviceLabel: '',
                capabilities: {},
                deviceId: '',
                toHide: false,
            },
        ],
        noiseCancellationEnabled: false,
        audioOutput: {
            originalDeviceLabel: '',
            deviceLabel: '',
            deviceLabelWithoutIds: '',
            deviceId: '',
            toHide: false,
        },
        portalDisplayScreenDetails: {},
        allScreens: [],
        message: '',
    },
};

export default getDefaultConfigSettings;
