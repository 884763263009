import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllPortalsByOrgIdAPI } from '../../api/getAllPortalsByOrgIdAPI';
import { getPortalByOrgIdPortalIdAPI } from '../../api/getPortalByOrgIdPortalIdAPI';
import { updatePortalByOrgIdPortalIdAPI } from '../../api/updatePortalByOrgIdPortalIdAPI';
import { registerPortalByOrgIdAPI } from '../../api/registerPortalByOrgIdAPI';
import { regenerateRegistrationKeyOfPortalAPI } from '../../api/regenerateRegistrationKeyOfPortalAPI';
import { archivePortalByOrgIdPortalIdAPI } from '../../api/archivePortalByOrgIdPortalIdAPI';
import { PortalModel } from '../model/portalModel';
import { getPortalByOrgIdPortalIdStatusAPI } from '../../api/getPortalByOrgIdPortalIdStatusAPI';
import { installAgentUpdatesAPI } from '../../api/installAgentUpdatesAPI';
import { agentUpdateInstalledAPI } from '../../api/agentUpdateInstalledAPI';
import { agentManualDownloadLinkAPI } from '../../api/agentManualDownloadLinkAPI';
import { ManualLinkDownloadModel } from '../model/manualLinkDownloadModel';
import { getPortalAgentUpdateStatusAPI } from '../../api/getPortalAgentUpdateStatusAPI';
import { reloadPAAPI } from '../../api/reloadPAAPI';
import { agentLogsAPI, latestAgentLogsAPI } from '../../api/agentLogsAPI';
import { getListOfPortalsAPI } from 'api/getListOfPortalsAPI';
import { regenerateAuthPinAPI } from 'api/regenerateAuthPinAPI';

const initialState = {
    allPortals: {
        data: [] as PortalModel[],
        pager: {},
    },
    loading: false,
    archivedLoading: false,
    error: false,
    errorObj: {} as any,
    selectedPortalId: '',
    portal: {} as PortalModel,
    updatedPortal: {} as PortalModel,
    portalApproved: {} as any,
    portalApprovedLoading: false,
    portalApprovedError: false,
    registerPortal: {} as PortalModel,
    regeneratedKey: {} as any,
    archivedPortal: {} as any,
    archivedPortalError: false,
    isCompLoader: { isChangeLoader: false, isSavedloader: false },
    portalForStatusUpdate: {} as PortalModel, // save the availability status of a portal temporarily
    portalForStatusListUpdate: {} as PortalModel, // save the potal list temporarily
    agentInstallationInitiated: {} as any,
    agentInstallationInitiatedLoading: false,
    agentInstallationInitiatedError: false,
    agentInstallationInitiatedErrorObj: {} as any,
    portalAgentUpdateInstalled: {} as any,
    portalAgentUpdateInstalledLoading: false,
    portalAgentUpdateInstalledError: false,
    portalAgentUpdateInstalledErrorObj: {} as any,
    agentManualDLink: {} as ManualLinkDownloadModel,
    agentManualDLinkLoading: false,
    agentManualDLinkError: false,
    agentManualDLinkErrorObj: {} as any,
    getPortalAgentUpdateStatus: {} as any,
    getPortalAgentUpdateStatusLoading: false,
    getPortalAgentUpdateStatusError: false,
    getPortalAgentUpdateStatusErrorObj: {} as any,
    reloadPALoading: false,
    reloadPAError: false,
    reloadPASuccess: {} as any,
    fetchLogsSuccess: {} as any,
    reloadPAErrorObj: {} as any,
    agentLogsLoading: false,
    agentLogsError: false,
    agentLogs: {} as any,
    agentLogsErrorObj: {} as any,
    latestLogsLoading: false,
    isSelectedPortalConnected: false,
    allPortalsData: {
        data: [] as PortalModel[],
        pager: {},
    },
    listLoading: false,
    isLogsUploaded: false,
    authPinLoading: false,
    regeneratedPin: '',
};

export const getAllPortalsByOrgId = createAsyncThunk('portal/getAllPortalsByOrgId', async (obj: any) => {
    return await getAllPortalsByOrgIdAPI(obj);
});

export const getPortalByOrgIdPortalId = createAsyncThunk('portal/getPortalByOrgIdPortalId', async (orgPortal: any) => {
    return await getPortalByOrgIdPortalIdAPI(orgPortal);
});

export const getPortalByOrgIdPortalIdStatus = createAsyncThunk(
    'portal/getPortalByOrgIdPortalIdStatus',
    async (orgPortal: any) => {
        return await getPortalByOrgIdPortalIdStatusAPI(orgPortal);
    },
); // API call for availability status of a portal

export const archivePortalByOrgIdPortalId = createAsyncThunk(
    'portal/archivePortalByOrgIdPortalId',
    async (archivedPortal: any) => {
        return await archivePortalByOrgIdPortalIdAPI(archivedPortal);
    },
);

export const updatePortalByOrgIdPortalId = createAsyncThunk(
    'portal/updatePortalByOrgIdPortalId',
    async (orgPortal: any) => {
        delete orgPortal.updatedPortal?.org_id;
        delete orgPortal.updatedPortal?.pk;
        delete orgPortal.updatedPortal?.sk;
        delete orgPortal.updatedPortal?.updated_on;
        delete orgPortal.updatedPortal?.portal_id;
        delete orgPortal.updatedPortal?.createdOn;
        delete orgPortal.updatedPortal?.status;
        delete orgPortal.updatedPortal?.is_approved;
        return await updatePortalByOrgIdPortalIdAPI(orgPortal);
    },
);

export const registerPortalByOrgId = createAsyncThunk('portal/registerPortalByOrgId', async (portalObj: any) => {
    delete portalObj.registerPortal.org_id;
    delete portalObj.registerPortal.pk;
    delete portalObj.registerPortal.sk;
    delete portalObj.registerPortal.updated_on;
    delete portalObj.registerPortal.portal_id;
    delete portalObj.registerPortal.createdOn;
    delete portalObj.registerPortal.status;
    delete portalObj.registerPortal.is_approved;
    return await registerPortalByOrgIdAPI(portalObj);
});

export const regenerateRegistrationKeyOfPortal = createAsyncThunk(
    'portal/regenerateRegistrationKeyOfPortal',
    async (portalObj: { orgId: string; portalId: string }) => {
        return await regenerateRegistrationKeyOfPortalAPI(portalObj.orgId, portalObj.portalId);
    },
);

export const installAgentUpdates = createAsyncThunk(
    'portal/installAgentUpdates',
    async (portalObj: { orgId: string; portalId: string; rest: { [key: string]: string } }, { rejectWithValue }) => {
        try {
            return await installAgentUpdatesAPI(portalObj.orgId, portalObj.portalId, portalObj.rest);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const agentUpdateInstalled = createAsyncThunk(
    'portal/agentUpdateInstalled',
    async (portalObj: { orgId: string; portalId: string }, { rejectWithValue }) => {
        try {
            return await agentUpdateInstalledAPI(portalObj.orgId, portalObj.portalId);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const agentManualDownloadLink = createAsyncThunk('portal/agentManualDownloadLink', async () => {
    return await agentManualDownloadLinkAPI();
});

export const getAgentUpdateStatus = createAsyncThunk(
    'portal/getAgentUpdateStatus',
    async (objPortal: { orgId: string; portalId: string }) => {
        return await getPortalAgentUpdateStatusAPI(objPortal);
    },
);
export const reloadPA = createAsyncThunk(
    'portal/reloadPA',
    async (portalObj: { orgId: string; portalId: string }, { rejectWithValue }) => {
        try {
            return await reloadPAAPI(portalObj.orgId, portalObj.portalId);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

// Generate New Authentication PIN.
export const regenerateAuthPin = createAsyncThunk(
    'portal/regenerateAuthPin',
    async (portalObj: { orgId: string; portalId: string }, { rejectWithValue }) => {
        try {
            return await regenerateAuthPinAPI(portalObj.orgId, portalObj.portalId);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const agentLogs = createAsyncThunk(
    'portal/agentLogs',
    async (portalObj: { orgId: string; portalId: string }, { rejectWithValue }) => {
        try {
            return await agentLogsAPI(portalObj.orgId, portalObj.portalId);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);
export const latestAgentLogs = createAsyncThunk(
    'portal/latestagentLogs',
    async (portalObj: { orgId: string; portalId: string; count: Number }, { rejectWithValue }) => {
        try {
            return await latestAgentLogsAPI(portalObj.orgId, portalObj.portalId, portalObj.count);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const getListOfPortals = createAsyncThunk(
    'portals/getListOfPortals',
    async (orgPortal: { orgId: string; limit: number; getAllPortals: boolean }) => {
        const response = await getListOfPortalsAPI(orgPortal);
        return response.data;
    },
);

const portalSlice = createSlice({
    name: 'portal',
    initialState,
    // callled for internal state updates
    reducers: {
        setLogsUploaded: (state, action) => {
            state.isLogsUploaded = action.payload;
        },
        setPortalsToPreviousState: (state) => {
            state.regeneratedKey = {};
            state.registerPortal = {} as PortalModel;
        },
        setEmptyArchivedPortal: (state) => {
            state.archivedPortal = {} as any;
        },
        setOnchangeLoader: (state, action) => {
            state.isCompLoader = {
                isChangeLoader: action.payload.isChangeLoader,
                isSavedloader: action.payload.isSavedloader,
            };
        },
        setPortalUpdateByStatus: (state, action) => {
            // update the currentportal's status if any change is observed
            state.portal = { ...action.payload };
        },
        setPortalEmpty: (state) => {
            // update the currentportal's status if any change is observed
            state.portal = {} as PortalModel;
        },
        setPortalUpdateByStatusList: (state, action) => {
            // update the current portal list if any change is observed
            state.allPortals.data = action.payload;
        },
        setAgentInstallationInitiatedEmpty: (state) => {
            // update the current portal list if any change is observed
            state.agentInstallationInitiated = {};
            state.agentInstallationInitiatedErrorObj = {};
        },
        setAgentUpdateStatusEmpty: (state) => {
            state.getPortalAgentUpdateStatus = {};
            state.getPortalAgentUpdateStatusError = false;
            state.getPortalAgentUpdateStatusLoading = false;
        },
        setAllPortalListPagination: (state, action) => {
            state.allPortals.data = action.payload.response;
            state.allPortals.pager = action.payload.filterSortParams;
        },
        resetReloadPA: (state) => {
            state.reloadPAError = false;
            state.reloadPAErrorObj = {} as any;
            state.reloadPALoading = false;
            state.reloadPASuccess = {} as any;
        },
        resetAgentLogs: (state) => {
            state.agentLogsError = false;
            state.agentLogsErrorObj = {} as any;
            state.agentLogsLoading = false;
            state.agentLogs = {} as any;
            state.fetchLogsSuccess = {} as any;
        },
        setPortalList(state, action) {
            const newOrgList = {
                data: action.payload,
                pager: action.payload.pager,
            };
            state.allPortals = newOrgList;
        },
        setPortal(state, action) {
            state.portal = action.payload;
        },
        setPortalPresence(state, action) {
            state.isSelectedPortalConnected = action.payload;
        },
        setPortalForStatusUpdateEmpty: (state) => {
            state.portalForStatusUpdate = {} as PortalModel;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPortalsByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.portalApproved = {};
                state.portalForStatusListUpdate = {} as PortalModel;
            })
            .addCase(getAllPortalsByOrgId.fulfilled, (state, action) => {
                /* const tempArr = [] as any;
action.payload.data.forEach((item: PortalModel) => {
    if (!('isArchived' in item) || ('isArchived' in item && !item.isArchived)) {
        tempArr.push(item);
    }
}); */
                if (
                    action.payload.hasOwnProperty('data') &&
                    action.payload.data &&
                    action.payload.data.hasOwnProperty('data') &&
                    action.payload.data.data &&
                    action.payload.data.hasOwnProperty('pager') &&
                    action.payload.data.pager
                ) {
                    state.allPortals = { data: action.payload.data.data, pager: action.payload.data.pager };
                } else {
                    state.allPortals = { data: [], pager: {} };
                }
                state.portalApproved = {};
            })
            .addCase(getAllPortalsByOrgId.rejected, (state, action) => {
                state.allPortals = {
                    data: [] as PortalModel[],
                    pager: {},
                };
                state.errorObj = action.error;
                state.portalApproved = {};
            })
            .addCase(getPortalByOrgIdPortalId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
                state.portal = {} as PortalModel;
                state.portalApproved = {};
            })
            .addCase(getPortalByOrgIdPortalId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    // eslint-disable-next-line prefer-const
                    let tempObj = JSON.parse(JSON.stringify(action.payload?.data));
                    if ('orientation' in tempObj.settings && !('qrNotiBanner' in tempObj.settings.orientation)) {
                        tempObj.settings.orientation.qrNotiBanner = 0;
                    }
                    const updatedPortalObj = updateBasedOnDisplayType(tempObj);
                    state.portal = { ...(updatedPortalObj as PortalModel) };
                    state.updatedPortal = {} as PortalModel;
                } else {
                    state.portal = {} as PortalModel;
                    state.updatedPortal = {} as PortalModel;
                    state.error = true;
                }
                state.archivedPortal = {};
                state.portalApproved = {};
            })
            .addCase(getPortalByOrgIdPortalId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.updatedPortal = {} as PortalModel;
                state.errorObj = action.error;
                state.portalApproved = {};
            })
            // don't update the loading variable as the action needs to be silent if there is no change in availability status of a portal
            .addCase(getPortalByOrgIdPortalIdStatus.pending, (state) => {
                // action is inferred correctly here if using TS
                // state.portalForStatusUpdate = {} as PortalModel;
            })
            .addCase(getPortalByOrgIdPortalIdStatus.fulfilled, (state, action) => {
                if (action?.payload?.data) {
                    // eslint-disable-next-line prefer-const
                    let tempObj = JSON.parse(JSON.stringify(action.payload?.data));
                    if ('orientation' in tempObj.settings && !('qrNotiBanner' in tempObj.settings.orientation)) {
                        tempObj.settings.orientation.qrNotiBanner = 0;
                    }
                    state.portalForStatusUpdate = tempObj as PortalModel;
                } else {
                    state.portalForStatusUpdate = {} as PortalModel;
                }
                state.archivedPortal = {};
                state.portalApproved = {};
            })
            .addCase(getPortalByOrgIdPortalIdStatus.rejected, (state) => {
                state.updatedPortal = {} as PortalModel;
                state.portalApproved = {};
            })

            .addCase(updatePortalByOrgIdPortalId.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.portalApproved = {};
                state.portal = {} as PortalModel;
                state.authPinLoading = true;
            })
            .addCase(updatePortalByOrgIdPortalId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.authPinLoading = false;
                if (action.payload['data']) {
                    state.updatedPortal = action.payload.data as PortalModel;
                    state.portal = {
                        ...state.portal,
                        authenticationStatus: action.payload?.data?.authenticationStatus,
                        securityPin: action.payload?.data?.securityPin,
                    };
                } else {
                    state.updatedPortal = {} as PortalModel;
                    state.error = true;
                }
                state.portalApproved = {};
            })
            .addCase(updatePortalByOrgIdPortalId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.portalApproved = {};
                state.authPinLoading = false;
            })

            .addCase(archivePortalByOrgIdPortalId.pending, (state) => {
                state.archivedLoading = true;
                state.archivedPortalError = false;
                state.archivedPortal = {} as PortalModel;
            })
            .addCase(archivePortalByOrgIdPortalId.fulfilled, (state, action) => {
                state.archivedLoading = false;
                state.archivedPortalError = false;
                if (action.payload['data']) {
                    state.archivedPortal = action.payload.data;
                } else {
                    state.archivedPortal = {} as any;
                    state.archivedPortalError = true;
                }
                state.portalApproved = {};
            })
            .addCase(archivePortalByOrgIdPortalId.rejected, (state, action) => {
                state.archivedLoading = false;
                state.archivedPortalError = true;
                state.errorObj = action.error;
                state.portalApproved = {};
            })

            .addCase(registerPortalByOrgId.pending, (state) => {
                state.portalApproved = {};
                state.loading = true;
                state.error = false;
                state.portal = {} as PortalModel;
            })
            .addCase(registerPortalByOrgId.fulfilled, (state, action) => {
                state.portalApproved = {};
                state.loading = false;
                state.error = false;
                if (action.payload['data']) {
                    state.registerPortal = action.payload.data as PortalModel;
                } else {
                    state.registerPortal = {} as PortalModel;
                    state.error = true;
                }
                state.archivedPortal = {} as any;
                state.archivedPortalError = false;
            })
            .addCase(registerPortalByOrgId.rejected, (state, action) => {
                state.portalApproved = {};
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(regenerateRegistrationKeyOfPortal.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(regenerateRegistrationKeyOfPortal.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.regeneratedKey = action.payload.data;
            })
            .addCase(regenerateRegistrationKeyOfPortal.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(installAgentUpdates.pending, (state) => {
                state.agentInstallationInitiatedLoading = true;
                state.agentInstallationInitiatedError = false;
                state.agentInstallationInitiated = {};
            })
            .addCase(installAgentUpdates.fulfilled, (state) => {
                state.agentInstallationInitiatedLoading = false;
                state.agentInstallationInitiatedError = false;
                state.agentInstallationInitiated.message = 'Agent installation initiated';
            })
            .addCase(installAgentUpdates.rejected, (state, action) => {
                state.agentInstallationInitiatedLoading = false;
                state.agentInstallationInitiatedError = true;
                state.agentInstallationInitiatedErrorObj = action.payload;
                state.agentInstallationInitiated = {};
            })
            .addCase(agentUpdateInstalled.pending, (state) => {
                state.portalAgentUpdateInstalledLoading = true;
                state.portalAgentUpdateInstalledError = false;
                state.portalAgentUpdateInstalled = {};
            })
            .addCase(agentUpdateInstalled.fulfilled, (state) => {
                state.portalAgentUpdateInstalledLoading = false;
                state.portalAgentUpdateInstalledError = false;
                state.portalAgentUpdateInstalled.message = 'Agent installation completed';
            })
            .addCase(agentUpdateInstalled.rejected, (state, action) => {
                state.portalAgentUpdateInstalledLoading = false;
                state.portalAgentUpdateInstalledError = true;
                state.portalAgentUpdateInstalledErrorObj = action.payload;
                state.portalAgentUpdateInstalled = {};
            })
            .addCase(agentManualDownloadLink.pending, (state) => {
                state.agentManualDLinkLoading = true;
                state.agentManualDLinkError = false;
                // state.agentManualDLink = {};
            })
            .addCase(agentManualDownloadLink.fulfilled, (state, action) => {
                state.agentManualDLink = action.payload.data;
                state.agentManualDLinkLoading = false;
                state.agentManualDLinkError = false;
            })
            .addCase(agentManualDownloadLink.rejected, (state, action) => {
                state.agentManualDLinkLoading = false;
                state.agentManualDLinkError = true;
                state.agentManualDLinkErrorObj = action.payload;
                // state.agentManualDLink = {};
            })
            .addCase(getAgentUpdateStatus.pending, (state) => {
                state.getPortalAgentUpdateStatusLoading = true;
                state.getPortalAgentUpdateStatusError = false;
                state.getPortalAgentUpdateStatus = {};
            })
            .addCase(getAgentUpdateStatus.fulfilled, (state, action) => {
                state.getPortalAgentUpdateStatusLoading = false;
                state.getPortalAgentUpdateStatusError = false;
                state.getPortalAgentUpdateStatus = action.payload.data;
            })
            .addCase(getAgentUpdateStatus.rejected, (state, action) => {
                state.getPortalAgentUpdateStatusLoading = false;
                state.getPortalAgentUpdateStatusError = true;
                state.getPortalAgentUpdateStatusErrorObj = action.payload;
                state.getPortalAgentUpdateStatus = {};
            })
            .addCase(reloadPA.pending, (state) => {
                state.reloadPALoading = true;
                state.reloadPAError = false;
                state.reloadPASuccess = {};
                state.reloadPAErrorObj = {};
            })
            .addCase(reloadPA.fulfilled, (state) => {
                state.reloadPALoading = false;
                state.reloadPAError = false;
                if (state.reloadPASuccess) {
                    state.reloadPASuccess = {
                        message: 'Portal Agent Refresh initiated',
                    };
                }
                state.reloadPAErrorObj = {};
            })
            .addCase(reloadPA.rejected, (state) => {
                state.reloadPALoading = false;
                state.reloadPAError = true;
                state.reloadPASuccess = {};
                if (Object.keys(state?.reloadPAErrorObj).length === 0) {
                    state.reloadPAErrorObj = {
                        message: 'Something went wrong!',
                    };
                }
            })
            .addCase(agentLogs.pending, (state) => {
                state.agentLogsLoading = true;
                state.agentLogsError = false;
                state.agentLogs = {};
                state.agentLogsErrorObj = {};
                state.fetchLogsSuccess = {};
            })
            .addCase(agentLogs.fulfilled, (state, action) => {
                state.agentLogsLoading = false;
                state.agentLogsError = false;
                state.agentLogs = action?.payload?.data;
                state.agentLogsErrorObj = {};
                if (state.fetchLogsSuccess) {
                    state.fetchLogsSuccess = {
                        message: 'Logs Fethcing Initiated',
                    };
                }
            })
            .addCase(agentLogs.rejected, (state, action) => {
                state.agentLogsLoading = false;
                state.agentLogsError = true;
                state.agentLogs = {};
                state.agentLogsErrorObj = action.payload;
                state.fetchLogsSuccess = {};
            })
            .addCase(latestAgentLogs.pending, (state) => {
                state.latestLogsLoading = true;
                state.agentLogsError = false;
                state.agentLogs = {};
                state.agentLogsErrorObj = {};
            })
            .addCase(latestAgentLogs.fulfilled, (state, action) => {
                state.latestLogsLoading = false;
                state.agentLogsError = false;
                state.agentLogs = action.payload.data;
                state.agentLogsErrorObj = {};
            })
            .addCase(latestAgentLogs.rejected, (state, action) => {
                state.latestLogsLoading = false;
                state.agentLogsError = true;
                state.agentLogs = {};
                state.agentLogsErrorObj = action.payload;
            })
            .addCase(getListOfPortals.pending, (state) => {
                // action is inferred correctly here if using TS
                state.listLoading = true;
                state.error = false;
            })
            .addCase(getListOfPortals.fulfilled, (state, action) => {
                state.listLoading = false;
                state.error = false;
                const list = [...action.payload.data];
                const newOrgList = {
                    data: list,
                    pager: action.payload.pager,
                };
                state.allPortalsData = newOrgList;
            })
            .addCase(getListOfPortals.rejected, (state, action) => {
                state.listLoading = false;
                state.error = true;
                state.errorObj = action.error;
                state.allPortalsData = { data: [], pager: {} };
            })
            .addCase(regenerateAuthPin.pending, (state) => {
                state.authPinLoading = true;
            })
            .addCase(regenerateAuthPin.fulfilled, (state, action) => {
                state.authPinLoading = false;
                state.portal = { ...state.portal, securityPin: action?.payload?.data?.securityPin };
            })
            .addCase(regenerateAuthPin.rejected, (state, action) => {
                state.authPinLoading = false;
            });

        // You can chain calls, or have separate `builder.addCase()` lines each time
        /* .addCase(decrement, (state, action) => {}) */
        // You can match a range of action types
        /* .addMatcher(
    isRejectedAction,
    // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
    (state, action) => {}
  ) */
        // and provide a default case if no other handlers matched
        /* .addDefaultCase((state, action) => {}) */
    },
});

export const {
    setPortalsToPreviousState,
    setEmptyArchivedPortal,
    setOnchangeLoader,
    setPortalUpdateByStatus,
    setPortalUpdateByStatusList,
    setAgentInstallationInitiatedEmpty,
    setPortalEmpty,
    setAgentUpdateStatusEmpty,
    setAllPortalListPagination,
    resetReloadPA,
    resetAgentLogs,
    setPortalList,
    setPortal,
    setPortalPresence,
    setLogsUploaded,
    setPortalForStatusUpdateEmpty,
} = portalSlice.actions;
export default portalSlice.reducer;

/**
 * Updates edge values based on rotation and display type
 * @param portalObj Recieved value of portal
 * @returns Updated value of portal
 */
const updateBasedOnDisplayType = (portalObj: PortalModel) => {
    const newObj = JSON.parse(JSON.stringify(portalObj));
    if (
        newObj &&
        newObj.settings &&
        newObj.settings.orientation &&
        newObj.settings.orientation.projectorRotation !== null &&
        newObj.settings.orientation.projectorRotation !== undefined &&
        !newObj.settings.orientation.hasOwnProperty('projectorARandDF')
    ) {
        newObj.settings.orientation.projectorARandDF = {
            lowerEdge: 100,
            projectorAR: '4:3',
            leftEdge: 100,
            upperEdge: 100,
            rightEdge: 100,
            projectorOrientation: '0',
        };
    }
    if (
        newObj &&
        newObj.settings &&
        newObj.settings.orientation &&
        newObj.settings.orientation.projectorRotation !== null &&
        newObj.settings.orientation.projectorRotation !== undefined &&
        newObj.settings.orientation.displayType === 'projector'
    ) {
        let switchLeft = newObj.settings.orientation.projectorARandDF?.leftEdge;
        let switchRight = newObj.settings.orientation.projectorARandDF?.rightEdge;
        let switchUpper = newObj.settings.orientation.projectorARandDF?.upperEdge;
        let switchBottom = newObj.settings.orientation.projectorARandDF?.lowerEdge;
        //left 85 right 90 upper 85 lower 80
        switch (newObj.settings.orientation.projectorRotation) {
            case 270:
                switchLeft = newObj.settings.orientation.projectorARandDF?.upperEdge;
                switchUpper = newObj.settings.orientation.projectorARandDF?.rightEdge;
                switchRight = newObj.settings.orientation.projectorARandDF?.lowerEdge;
                switchBottom = newObj.settings.orientation.projectorARandDF?.leftEdge;
                break;
            case 90:
                switchLeft = newObj.settings.orientation.projectorARandDF?.lowerEdge;
                switchUpper = newObj.settings.orientation.projectorARandDF?.leftEdge;
                switchRight = newObj.settings.orientation.projectorARandDF?.upperEdge;
                switchBottom = newObj.settings.orientation.projectorARandDF?.rightEdge;
                break;
        }
        newObj.settings.orientation.projectorARandDF.leftEdge = switchLeft;
        newObj.settings.orientation.projectorARandDF.rightEdge = switchRight;
        newObj.settings.orientation.projectorARandDF.upperEdge = switchUpper;
        newObj.settings.orientation.projectorARandDF.lowerEdge = switchBottom;
        return newObj;
    }
    return newObj;
};
