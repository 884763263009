// Built-in
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// External libraries
import _ from 'lodash';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Internal project-specific
import { RootState } from '../../redux-toolkit/reducer';
import {
    agentManualDownloadLink,
    regenerateRegistrationKeyOfPortal,
    setAgentUpdateStatusEmpty,
} from '../../redux-toolkit/reducer/portalReducer';
import { PORTAL_STATES, TOAST_FADEOUT, LATEST_VERSION_NOT_INSTALLED, LOCAL_ENV_PORTAL_AGENT } from '../../utils';
import { setToast } from '../../redux-toolkit/reducer/toastReducer';
import When from '../when/When';

const PortalStatusCard = (props: any) => {
    const dispatch = useDispatch();
    const params: { orgId: ''; portalId: '' } = useParams();

    const [showRegenerateKeyBtn, setShowRegenerateKeyBtn] = useState(false);
    const NEW_REGISTRATION_INTEGRATION_TIMESTAMP = 1659695841000;
    const [isNewVersionAvail, setIsNewVersionAvail] = useState(false);
    const [isUpdateDownloaded, setIsUpdateDownloaded] = useState(false);
    const [currentPAVersion, setCurrentPAVersion] = useState('');
    // The useRef Hook allows you to persist data between renders
    const prevPAVersion = useRef<string>('');

    const updateLoader = JSON.parse(localStorage.getItem('updateLoader') ?? '{}');
    const [loader, setLoader] = useState(
        JSON.parse(localStorage.getItem('updateLoader') ?? '{}')[params.portalId] ?? false,
    );

    const regenerateRegistrationKey = () => {
        dispatch(regenerateRegistrationKeyOfPortal({ orgId: params.orgId, portalId: params.portalId }));
        setShowRegenerateKeyBtn(false);
    };

    const {
        regeneratedKey,
        agentManualDLink,
        getPortalAgentUpdateStatus,
        getPortalAgentUpdateStatusError,
        getPortalAgentUpdateStatusLoading,
    } = useSelector(
        ({ portal }: RootState) => ({
            regeneratedKey: portal.regeneratedKey,
            agentManualDLink: portal.agentManualDLink,
            getPortalAgentUpdateStatus: portal.getPortalAgentUpdateStatus,
            getPortalAgentUpdateStatusLoading: portal.getPortalAgentUpdateStatusLoading,
            getPortalAgentUpdateStatusError: portal.getPortalAgentUpdateStatusError,
        }),
        _.isEqual,
    );

    const isManualDownloadLinkReady =
        agentManualDLink?.hasOwnProperty('objectUrl') && agentManualDLink?.hasOwnProperty('expiresAt');

    const agentUpdateInstalled = () => {
        setLoader(false);
        setIsNewVersionAvail(false);
        localStorage.setItem('updateLoader', JSON.stringify({ ...updateLoader, [`${params.portalId}`]: false }));
        props.agentUpdateInstalled();
    };

    const setToastContent = (toastContent: string) => props.setToastContent(toastContent);

    const isLocalEnvPortal = (version: string) => version !== LOCAL_ENV_PORTAL_AGENT;

    const isLatestVersionNotInstalled = (comparison: number) => comparison === LATEST_VERSION_NOT_INSTALLED;

    const setPAUpdateAvailable = (isLatestYetToInstall: boolean, installedVersion: string) =>
        setIsNewVersionAvail(isLatestYetToInstall && isLocalEnvPortal(installedVersion));

    const setPAUpdateDownloaded = (isLatestYetToInstall: boolean, hasDownloaded: boolean) =>
        setIsUpdateDownloaded(isLatestYetToInstall && hasDownloaded);

    const setUpdateStates = useCallback((current: string | null, latest: string | null, hasDownloaded: boolean) => {
        if (!latest || !current) {
            setIsNewVersionAvail(false);
            setIsUpdateDownloaded(false);
            return;
        }
        const isLatestYetToInstall = isLatestVersionNotInstalled(compareVersions(current, latest));
        setPAUpdateAvailable(isLatestYetToInstall, current);
        setPAUpdateDownloaded(isLatestYetToInstall, hasDownloaded);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!props.currentPortal) return;
        const {
            agentVersion: currentPortalVersion,
            latestAgentVersionDetails,
            isAgentUpdateDownloaded,
        } = props.currentPortal;
        if (latestAgentVersionDetails?.version) {
            const { version: latestVersionNumber } = latestAgentVersionDetails;
            setUpdateStates(currentPortalVersion, latestVersionNumber, isAgentUpdateDownloaded);
        }
    }, [props.currentPortal, setUpdateStates]);

    /**
   * This will return:

    0: version strings are equal
    1: version currentVersion is greater than latestVersion
    -1: version latestVersion is greater than currentVersion
   */
    const compareVersions = (currentVersion: string, latestVersion: string): number =>
        currentVersion.localeCompare(latestVersion, undefined, { numeric: true, sensitivity: 'base' });

    const statusChip = () => {
        if (props?.currentPortal && !!props?.currentPortal?.isArchived) {
            return <div className="status-text pending-activation body5-secondary">Archived</div>;
        } else if (
            props.currentPortal &&
            (('isActivated' in props.currentPortal && !props.currentPortal.isActivated) ||
                props.currentPortal.portalConnectionStatus === PORTAL_STATES.PENDING) &&
            props.currentPortal.portalConnectionStatus !== PORTAL_STATES.DISCONNECTED
        ) {
            return <div className="status-text pending-activation body5-secondary">Pending Activation</div>;
        } else if (
            props.currentPortal &&
            'is_approved' in props.currentPortal &&
            !props.currentPortal.is_approved &&
            props.currentPortal.createdOn < NEW_REGISTRATION_INTEGRATION_TIMESTAMP &&
            !('isActivated' in props.currentPortal) &&
            props.currentPortal.portalConnectionStatus !== PORTAL_STATES.DISCONNECTED
        ) {
            return <div className="status-text pending-activation body5-secondary">Pending Activation</div>;
        } else if (
            props.currentPortal &&
            (('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                props.currentPortal.is_approved) &&
            props.currentPortal.portalConnectionStatus === PORTAL_STATES.IN_CALL
        ) {
            return <div className="status-text in-connection body5-secondary">In Connection</div>;
        } else if (
            props.currentPortal &&
            (('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                props.currentPortal.is_approved) &&
            props.currentPortal.portalConnectionStatus === PORTAL_STATES.ONLINE
        ) {
            return <div className="status-text available body5-secondary">Available</div>;
        } else if (
            props.currentPortal &&
            (('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                props.currentPortal.is_approved) &&
            props.currentPortal.portalConnectionStatus === PORTAL_STATES.OFFLINE
        ) {
            return <div className="status-text offline body5-secondary">Offline</div>;
        } else if (
            props.currentPortal &&
            (('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                props.currentPortal.is_approved) &&
            props.currentPortal.portalConnectionStatus === PORTAL_STATES.IN_MAINTENANCE
        ) {
            return <div className="status-text in-maintenance body5-secondary">In Maintenance</div>;
        } else if (props.currentPortal && props.currentPortal.portalConnectionStatus === PORTAL_STATES.ERROR) {
            return <div className="status-text offline body5-secondary">Error</div>;
        } else if (props.currentPortal && props.currentPortal.portalConnectionStatus === PORTAL_STATES.DISCONNECTED) {
            return <div className="status-text body5-secondary">Disconnected</div>;
        } else if (props?.currentPortal?.portalConnectionStatus === PORTAL_STATES.BOOKED) {
            return <div className="status-text pending-activation body5-secondary">Booked</div>;
        }
    };

    /* const updateMaintenanceStatus = (e: any) => {
        props.updateMainenance(e.target.checked);
    }; */

    const updateAgentVersion = () => {
        if (
            props.currentPortal &&
            (('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                props.currentPortal.is_approved) &&
            props.currentPortal.portalConnectionStatus !== PORTAL_STATES.ONLINE
        ) {
            setToastContent('You can only apply this update when the portal is online and available.');
        } else {
            setLoader(true);
            localStorage.setItem('updateLoader', JSON.stringify({ ...updateLoader, [`${params.portalId}`]: true }));
            dispatch(setToast({ type: '', message: '', msgHeader: '', show: false }));
            props.updateAgentVersion();
        }
    };

    const manualAgentDownloadLinkDiv = (isOnAddNodePage: boolean) => (
        <div className="body3-secondary no-list-avail dark">
            {!isOnAddNodePage
                ? `Alternatively, download the newer version from these links to update manually or by using your organization’s configuration management tools.`
                : `Using these links, download the PortalAgent to the portal computer. Install and launch it. Finally, enter the registration key displayed here once you have clicked on ‘Register’ on this page.`}
            <br />

            <span className="body3-secondary">
                <span>MacOS:&ensp;&ensp;</span>
                <span className="black">
                    <a href={Object.values(agentManualDLink?.objectUrl['mac'][3])[0]}>
                        <u>DMG</u>
                    </a>
                    &ensp;&ensp;
                    <a href={Object.values(agentManualDLink?.objectUrl['mac'][2])[0]}>
                        <u>DMG(ARM64)</u>
                    </a>
                    &ensp;&ensp;
                    {/* <a href={Object.values(agentManualDLink.objectUrl['mac'][1])[0]}><u>ZIP</u></a>&ensp;&ensp;
        <a href={Object.values(agentManualDLink.objectUrl['mac'][0])[0]}><u>ZIP(ARM64)</u></a>&ensp;&ensp; */}
                </span>
            </span>

            <br />

            <span className="body3-secondary">
                <span>Windows:&ensp;&ensp;</span>
                <span className="black">
                    <a href={Object.values(agentManualDLink?.objectUrl['windows'][0])[0]}>
                        <u>EXE</u>
                    </a>
                </span>
            </span>

            {/* <span>
      <span>MacOS: </span>
      <span className="d-flex align-items-start justify-content-between">
        <a href={Object.values(agentManualDLink.objectUrl['mac'][3])[0]}><u>DMG</u></a>
        <a href={Object.values(agentManualDLink.objectUrl['mac'][2])[0]}><u>DMG(ARM64)</u></a>
        <a href={Object.values(agentManualDLink.objectUrl['mac'][1])[0]}><u>ZIP</u></a>
        <a href={Object.values(agentManualDLink.objectUrl['mac'][0])[0]}><u>ZIP(ARM64)</u></a>
      </span>
    </span>

    <span>
      <span>Windows: </span>
      <span className="d-flex align-items-start justify-content-between">
        <a href={Object.values(agentManualDLink.objectUrl['windows'][0])[0]}><u>EXE</u></a>
      </span>
    </span> */}
        </div>
    );

    const abcdCasesForAutoUpdate = () => {
        if (
            props.currentPortal &&
            (('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                props.currentPortal.is_approved) &&
            'latestAgentVersionDetails' in props.currentPortal &&
            props.currentPortal.latestAgentVersionDetails.version &&
            !loader &&
            isNewVersionAvail
        ) {
            // console.log(
            //     'abcdCasesForAutoUpdate',
            //     props.currentPortal,
            //     ('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
            //         props.currentPortal.is_approved,
            //     'latestAgentVersionDetails' in props.currentPortal,
            //     props.currentPortal.latestAgentVersionDetails.version,
            //     loader,
            //     isNewVersionAvail,
            // );
            // console.log(
            //     'abcdCasesForAutoUpdate 1111',
            //     props.isUpdatedDownloadedOnPA,
            //     props.currentPortal.portalConnectionStatus,
            // );
            // case A : New version is available but portal is offline
            if (
                !props.isUpdatedDownloadedOnPA &&
                props.currentPortal.portalConnectionStatus !== PORTAL_STATES.ONLINE &&
                props.currentPortal.portalConnectionStatus !== PORTAL_STATES.IN_MAINTENANCE
            ) {
                return (
                    <div className="body3-secondary no-list-avail">
                        {`v${props.currentPortal.latestAgentVersionDetails.version} - A newer version is available and will automatically be downloaded when the portal is online and available.`}
                    </div>
                );
            }

            // case B : A new version is available and is being downloaded on portal
            else if (
                !props.isUpdatedDownloadedOnPA &&
                (props.currentPortal.portalConnectionStatus === PORTAL_STATES.ONLINE ||
                    props.currentPortal.portalConnectionStatus === PORTAL_STATES.IN_MAINTENANCE)
            ) {
                return (
                    <div className="body3-secondary no-list-avail">
                        {`v${props.currentPortal.latestAgentVersionDetails.version} - A newer version is being downloaded. Check for updates here to apply it.`}
                    </div>
                );
            }

            // case C : New version is available and has been downloaded but portal is offline
            else if (
                props.isUpdatedDownloadedOnPA &&
                props.currentPortal.portalConnectionStatus !== PORTAL_STATES.ONLINE &&
                props.currentPortal.portalConnectionStatus !== PORTAL_STATES.IN_MAINTENANCE
            ) {
                return (
                    <div className="body3-secondary no-list-avail">
                        {`v${props.currentPortal.latestAgentVersionDetails.version} - A newer version is available and has been downloaded. You can apply it when the portal is online and available, or in maintenance.`}
                    </div>
                );
            }

            // case D : New version is available and downloaded and portal is also online
            else if (
                props.isUpdatedDownloadedOnPA &&
                (props.currentPortal.portalConnectionStatus === PORTAL_STATES.ONLINE ||
                    props.currentPortal.portalConnectionStatus === PORTAL_STATES.IN_MAINTENANCE)
            ) {
                return (
                    <div className="body3-secondary no-list-avail green">
                        {`v${props.currentPortal.latestAgentVersionDetails.version} - A newer version is available and has been downloaded. Click the “Update” button to apply it.`}
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        if (props?.currentPortal?.agentVersion) {
            setCurrentPAVersion(props?.currentPortal?.agentVersion);
        }
    }, [props.currentPortal]);

    useEffect(() => {
        const comparison = compareVersions(currentPAVersion, prevPAVersion.current);
        // console.log('comparison', comparison, currentPAVersion, prevPAVersion.current, loader);
        if (loader) {
            if (comparison === 1) {
                agentUpdateInstalled();
            } else {
                setLoader(false);
                localStorage.setItem(
                    'updateLoader',
                    JSON.stringify({ ...updateLoader, [`${params.portalId}`]: false }),
                );
                // dispatch(
                //     setToast({
                //         type: 'error',
                //         message: 'PortalAgent update failed',
                //         msgHeader: 'Error!',
                //         show: true,
                //     }),
                // );
            }
            prevPAVersion.current = currentPAVersion;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPAVersion]);

    // useEffect(() => {
    //     let interval: NodeJS.Timeout | undefined;
    //     if (loader) {
    //         interval = setInterval(() => {
    //             dispatch(getAgentUpdateStatus({ orgId: params.orgId, portalId: params.portalId }));
    //         }, 3000);
    //     }
    //     return () => clearInterval(interval);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [loader]);

    useEffect(() => {
        if (
            !getPortalAgentUpdateStatusLoading &&
            !getPortalAgentUpdateStatusError &&
            getPortalAgentUpdateStatus &&
            loader &&
            'updateInProgress' in getPortalAgentUpdateStatus &&
            !getPortalAgentUpdateStatus.updateInProgress &&
            'status' in getPortalAgentUpdateStatus &&
            getPortalAgentUpdateStatus.status &&
            'message' in getPortalAgentUpdateStatus &&
            getPortalAgentUpdateStatus.message
        ) {
            const { message, status } = getPortalAgentUpdateStatus;
            if (status === 'success') {
                dispatch(setToast({ type: status, message: message, msgHeader: 'Success!', show: true }));
            } else {
                dispatch(setToast({ type: status, message: message, msgHeader: 'Error!', show: true }));
            }
            setTimeout(() => {
                dispatch(setToast({ type: '', message: '', msgHeader: '', show: false }));
                dispatch(setAgentUpdateStatusEmpty());
            }, TOAST_FADEOUT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPortalAgentUpdateStatus, getPortalAgentUpdateStatusLoading, getPortalAgentUpdateStatusError, loader]);

    useEffect(() => {
        if (props?.currentPortal?.latestAgentVersionDetails?.version && props?.currentPortal?.agentVersion) {
            const latestVersionNumber: string = props.currentPortal.latestAgentVersionDetails.version;
            const currentPortalVersion: string = props.currentPortal.agentVersion;
            const notDevMachine = currentPortalVersion !== '0.0.0';
            const comparison = compareVersions(currentPortalVersion, latestVersionNumber);
            if (latestVersionNumber && currentPortalVersion && comparison === -1 && notDevMachine) {
                // console.log(
                //     'latestAgentVersionDetails',
                //     latestVersionNumber,
                //     currentPortalVersion,
                //     comparison === -1,
                //     notDevMachine,
                // );
                setIsNewVersionAvail(true);
            } else {
                setIsNewVersionAvail(false);
            }
            // 'props?.isUpdatedDownloadedOnPA' using this flag since we will get the updated flag over here from the polling API
            if (latestVersionNumber && currentPortalVersion && comparison === -1 && props?.isUpdatedDownloadedOnPA) {
                setIsUpdateDownloaded(true);
            } else {
                setIsUpdateDownloaded(false);
            }
        } else {
            setIsNewVersionAvail(false);
            setIsUpdateDownloaded(false);
        }
    }, [props?.currentPortal, props?.isUpdatedDownloadedOnPA]);

    useEffect(() => {
        if (isNewVersionAvail || props.currentPortal === null) {
            dispatch(agentManualDownloadLink());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewVersionAvail, props.currentPortal]);

    const updateInProgress = useMemo(() => {
        return getPortalAgentUpdateStatus.updateInProgress;
    }, [getPortalAgentUpdateStatus.updateInProgress]);

    useEffect(() => {
        // let timeoutId: NodeJS.Timeout | undefined;
        if (updateInProgress) {
            setLoader(true);
        }

        // return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateInProgress]);

    useEffect(() => {
        if (getPortalAgentUpdateStatus?.status === 'error' || getPortalAgentUpdateStatus?.status === 'success') {
            // console.log('(getPortalAgentUpdateStatus status', getPortalAgentUpdateStatus?.status);
            setLoader(false);
        }
    }, [getPortalAgentUpdateStatus?.status]);

    useEffect(() => {
        if (!loader) {
            setLoader(false);
            localStorage.setItem('updateLoader', JSON.stringify({ ...updateLoader, [`${params.portalId}`]: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loader]);

    useEffect(() => {
        if (regeneratedKey && JSON.stringify(regeneratedKey) !== '{}') {
            if (
                regeneratedKey &&
                'registrationKey' in regeneratedKey &&
                'registrationKeyExpiration' in regeneratedKey
            ) {
                if (regeneratedKey.registrationKeyExpiration < new Date().getTime()) {
                    setTimeout(() => setShowRegenerateKeyBtn(true), 0);
                } else {
                    setTimeout(
                        () => setShowRegenerateKeyBtn(false),
                        regeneratedKey.registrationKeyExpiration - new Date().getTime(),
                    );
                }
            }
        } else {
            if (
                props.currentPortal &&
                'isActivated' in props.currentPortal &&
                'registrationKey' in props.currentPortal &&
                'registrationKeyExpiration' in props.currentPortal &&
                !props?.currentPortal.isActivated
            ) {
                if (props.currentPortal.registrationKeyExpiration < new Date().getTime()) {
                    setTimeout(() => setShowRegenerateKeyBtn(true), 0);
                } else {
                    setTimeout(
                        () => setShowRegenerateKeyBtn(false),
                        props.currentPortal.registrationKeyExpiration - new Date().getTime(),
                    );
                }
            } else if (
                props.currentPortal &&
                'isActivated' in props.currentPortal &&
                'registrationKey' in props.currentPortal &&
                'registrationKeyExpiration' in props.currentPortal &&
                (props?.currentPortal.portalConnectionStatus === PORTAL_STATES.DISCONNECTED ||
                    props?.currentPortal.portalConnectionStatus === PORTAL_STATES.PENDING) &&
                regeneratedKey &&
                Object.keys(regeneratedKey).length <= 0
            ) {
                if (props.currentPortal.registrationKeyExpiration < new Date().getTime()) {
                    setTimeout(() => setShowRegenerateKeyBtn(true), 0);
                } else {
                    setTimeout(
                        () => setShowRegenerateKeyBtn(false),
                        props.currentPortal.registrationKeyExpiration - new Date().getTime(),
                    );
                }
            }
        }
    }, [props.currentPortal, regeneratedKey]);

    return (
        <>
            <div className="status-card">
                <When isTrue={props.currentPortal !== null}>
                    <div className="body1-primary portal-status-chip align-items-center">
                        Status
                        {statusChip()}
                        {/* <When isTrue={props.currentPortal}>
                            <div
                                className={`pill-container ml-auto ${
                                    props?.currentPortal?.portalConnectionStatus === PORTAL_STATES.DISCONNECTED ||
                                    props?.currentPortal?.portalConnectionStatus === PORTAL_STATES.OFFLINE ||
                                    props?.currentPortal?.portalConnectionStatus === PORTAL_STATES.IN_CALL
                                        ? 'disabled'
                                        : ''
                                }`}
                            >
                                <div className="pill-text mr-2 align-self-center body3-secondary">Maintenance</div>
                                <CSwitch
                                    onChange={updateMaintenanceStatus.bind(this)}
                                    defaultChecked={props?.currentPortal?.inMaintenance}
                                    className="custom-pill"
                                    color="primary"
                                    shape="pill"
                                    disabled={true}
                                />
                            </div>
                        </When> */}
                    </div>
                </When>
                <When isTrue={props.currentPortal}>
                    <div className="body1-primary">
                        <span className="reg-no-select">Node ID </span>
                        <span className="portalId black">
                            {props.currentPortal && props.currentPortal.portal_id ? props.currentPortal.portal_id : '-'}
                        </span>
                    </div>
                </When>
                <When isTrue={props.currentPortal === null}>
                    <div className="body1-primary">
                        Registration key <span className="black">XXX-XXX-XXX</span>
                    </div>
                </When>

                {props.currentPortal &&
                (props.currentPortal.portalConnectionStatus === PORTAL_STATES.DISCONNECTED ||
                    props.currentPortal.portalConnectionStatus === PORTAL_STATES.PENDING ||
                    ('registrationKey' in props.currentPortal &&
                        'registrationKeyExpiration' in props.currentPortal &&
                        'isActivated' in props.currentPortal &&
                        !props.currentPortal.isActivated)) &&
                'isArchived' in props.currentPortal &&
                !props.currentPortal.isArchived ? (
                    <div className="body1-primary">
                        <span className="reg-no-select">Registration key</span>
                        <span className="black">
                            {regeneratedKey && regeneratedKey?.registrationKey && (
                                <span
                                    className={`body1-primary registration-key ${showRegenerateKeyBtn ? 'strike' : ''}`}
                                >
                                    {regeneratedKey?.registrationKey}
                                </span>
                            )}
                            {regeneratedKey &&
                                !('registrationKey' in regeneratedKey) &&
                                props.currentPortal &&
                                props.currentPortal.registrationKey && (
                                    <span
                                        className={`body1-primary registration-key ${
                                            showRegenerateKeyBtn ? 'strike' : ''
                                        }`}
                                    >
                                        {props?.currentPortal?.registrationKey}
                                    </span>
                                )}
                            <When
                                isTrue={
                                    regeneratedKey &&
                                    !regeneratedKey.hasOwnProperty('registrationKey') &&
                                    (props.currentPortal.portalConnectionStatus === PORTAL_STATES.DISCONNECTED ||
                                        props.currentPortal.portalConnectionStatus === PORTAL_STATES.PENDING ||
                                        ('registrationKey' in props.currentPortal &&
                                            'registrationKeyExpiration' in props.currentPortal &&
                                            'isActivated' in props.currentPortal &&
                                            !props.currentPortal.isActivated)) &&
                                    'isArchived' in props.currentPortal &&
                                    !props.currentPortal.isArchived &&
                                    props.currentPortal.registrationKey === null
                                }
                            >
                                <span className={`body1-primary registration-key`}>XXX-XXX-XXX</span>
                            </When>
                            <When isTrue={showRegenerateKeyBtn}>
                                <span className="regenerate-key ml-2" onClick={regenerateRegistrationKey}>
                                    Re-generate Key
                                </span>
                            </When>
                        </span>
                    </div>
                ) : (
                    <></>
                )}
                <When isTrue={props.currentPortal}>
                    <div className="body1-primary">
                        <span className="reg-no-select">Created </span>
                        <span className="portalCreationDate black">
                            {props?.currentPortal?.createdOn
                                ? `${new Date(props?.currentPortal?.createdOn).toLocaleDateString('en-US', {
                                      month: '2-digit',
                                      day: '2-digit',
                                      year: 'numeric',
                                  })}`
                                : '01/25/2020'}
                        </span>
                    </div>
                </When>
                <When isTrue={props.currentPortal === null}>
                    <div className="body1-primary">
                        <span className="reg-no-select">Created </span>
                        <span className="portalCreationDate black">
                            {new Date().toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                            })}
                        </span>
                    </div>
                </When>
                <When isTrue={props.currentPortal && !('agentVersion' in props.currentPortal)}>
                    <div className="body1-primary d-flex version-container">
                        <span className="reg-no-select">Software Version </span>
                        <span className="portal-version black">{!props?.currentPortal?.is_approved ? 'NA' : '-'}</span>
                    </div>
                </When>
                <When isTrue={props.currentPortal && props.currentPortal.agentVersion}>
                    <div className="body1-primary d-flex version-container">
                        <span className="reg-no-select">Software Version </span>
                        {props.currentPortal && props.currentPortal.agentVersion ? (
                            <span className="portal-version black">v{props.currentPortal.agentVersion}</span>
                        ) : (
                            '-'
                        )}
                        <div className="btn-text-container update-container">
                            <When isTrue={loader}>
                                <span className="spinner-container">
                                    <Spinner animation="border" variant="light">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </span>
                            </When>
                            <When isTrue={!loader && isNewVersionAvail}>
                                <div
                                    className={`btn-circle-new-cont ${
                                        isUpdateDownloaded &&
                                        (props?.currentPortal?.portalConnectionStatus === PORTAL_STATES.ONLINE ||
                                            props?.currentPortal?.portalConnectionStatus ===
                                                PORTAL_STATES.IN_MAINTENANCE)
                                            ? 'enabled'
                                            : 'disabled'
                                    }`}
                                    onClick={updateAgentVersion}
                                >
                                    <button className="btn-circle1 sm4">
                                        <i className="icon-update font-19px" />
                                    </button>
                                    <div className="text-cont body3-secondary">Update</div>
                                </div>
                            </When>
                            <When isTrue={!loader && !isNewVersionAvail}>
                                <div className={`btn-circle-new-cont`} style={{ cursor: 'default' }}>
                                    <div className="text-cont body3-secondary black">Up-to-date</div>
                                </div>
                            </When>
                        </div>
                    </div>
                </When>

                {abcdCasesForAutoUpdate()}
                {props.currentPortal &&
                    (('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                        props.currentPortal.is_approved) &&
                    'latestAgentVersionDetails' in props.currentPortal &&
                    !props.currentPortal.isArchived &&
                    props.currentPortal.latestAgentVersionDetails.version &&
                    !loader &&
                    isNewVersionAvail &&
                    isManualDownloadLinkReady &&
                    manualAgentDownloadLinkDiv(false)}
                {/* Is portal is not activated or on new portal reg page */}
                {(props.currentPortal === null ||
                    (props.currentPortal &&
                        !(
                            ('isActivated' in props.currentPortal && props.currentPortal.isActivated) ||
                            props.currentPortal.is_approved
                        ) &&
                        'latestAgentVersionDetails' in props.currentPortal &&
                        !props.currentPortal.isArchived &&
                        props.currentPortal.latestAgentVersionDetails.version &&
                        !loader &&
                        !isNewVersionAvail &&
                        !isUpdateDownloaded &&
                        (props.currentPortal.portalConnectionStatus === PORTAL_STATES.OFFLINE ||
                            props.currentPortal.portalConnectionStatus === PORTAL_STATES.PENDING))) &&
                    isManualDownloadLinkReady &&
                    manualAgentDownloadLinkDiv(true)}
            </div>
        </>
    );
};

export default React.memo(PortalStatusCard);
